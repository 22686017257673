<script setup lang="ts">
import type { PropType } from 'vue'
import type { Objects } from '~/types'
import { PathType } from '~/enums'

const props = defineProps({
	item: {
		type: Object as PropType<Objects>,
		required: true
	}
})

const { t: tg } = useI18n({
	useScope: 'global'
})
</script>

<template>
	<div
		class="flex flex-col bg-white rounded-lg shadow-md mb-1 pb-2"
	>
		<UiGallery
			:id="item.id"
			:images="item.images?.length ? [item.images[0]] : []"
			:youtube-urls="item.youtube_urls?.length ? [item.youtube_urls[0]] : []"
			:thumb-number="3"
			:width="!$isMobile ? 300 : 450"
			:height="!$isMobile ? 200 : 300"
			:show-arrows="false"
			:show-thumbs="false"
			:scroll-enabled="false"
			:enable-fancybox="false"
			:go-title="item.title"
			:go-url="'/' + PathType.Buy + '/' + item.slug"
			class="md:max-w-full min-h-[206px] !rounded-md"
		/>
		<div class="flex flex-1 flex-col mt-2 px-4">
			<NuxtLink
				class="text-teal-800 text-lg font-bold !leading-tight"
				:to="'/' + PathType.Buy + '/' + item.slug"
			>
				{{ item.title}}
			</NuxtLink>
			<NuxtLink
				v-if="item.village"
				:to="'/' + PathType.Village + '/' + item.village.slug"
				class="text-teal-800 text-md md:text-base !leading-tight"
			>
				{{ item.village.title }}
			</NuxtLink>
			<div
				v-if="item.location?.value"
				class="text-gray-600 text-sm font-normal mt-2 md:mt-1"
			>
				{{ item.location.value }}
			</div>
			<div
				v-if="item.highways?.length"
				class="flex flex-col mt-4 md:mt-2 mb-2"
			>
				<div class="flex gap-1 items-center">
					<div class="w-5 flex items-center justify-center">
						<UIcon
							name="i-app-car"
							class="text-teal-800 w-3 h-3"
						/>
					</div>
					<span class="text-gray-600 text-sm font-normal leading-normal">
            {{ item.highways[0].title }}
          </span>
					<template v-if="item.location?.beltway_distance">
						<span class="text-gray-600 text-sm font-light leading-normal">
							/
						</span>
						<span class="text-teal-800 text-sm font-normal leading-normal">
							{{ item.location.beltway_distance }} {{ tg('unit.km') }}
						</span>
					</template>
				</div>
			</div>
			<div class="text-gray-600 text-2xl font-bold mt-auto">
				{{ $n(item.price, 'currency', { currency: item.price_currency }) }}
			</div>
		</div>
	</div>
</template>