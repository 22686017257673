<script setup lang="ts">

const { t } = useI18n({
	useScope: 'local'
})

const items = [
	{
		label: t('item.plots_without_contract'),
		to: '/kupit-uchastok',
		image: 'plots_without_contract',
		class: 'order-1 md:order-1 col-span-1 md:col-span-2 max-md:!bg-none md:bg-left',
		text_class: 'right-5 top-1/2 -mt-6 text-right max-w-44'
	},
	{
		label: t('item.plots_with_contract'),
		to: '/kupit-dom',
		image: 'plots_with_contract',
		class: 'order-2 md:order-2 cols-span-1 md:col-span-1 bg-cover',
		text_class: 'right-5 top-1/2 -mt-6 text-right max-w-44 text-white'
	},
	{
		label: t('item.houses_complete_up_to_10_million'),
		to: '/kupit-dom/to-10000000-rub',
		image: 'houses_complete_up_to_10_million',
		class: 'order-3 md:order-3 col-span-2 !bg-right bg-auto !bg-[#d8d9dd] max-md:bg-[length:150px] md:bg-[length:150px] lg:bg-[length:210px] xl:bg-[length:300px] md:bg-right',
		text_class: 'left-5 top-1/2 -mt-6 max-w-44'
	},
	{
		label: t('item.plots_business_class'),
		to: '/poselki/biznes-klass',
		image: 'plots_business_class',
		class: 'order-4 md:order-4 col-span-1 md:col-span-1 md:h-[200px] bg-cover',
		text_class: 'left-5 bottom-4 max-w-32 md:max-w-44 text-white'
	},
	{
		label: t('item.plots_comfort_class'),
		to: '/poselki/ekonom-klass?class=komfort-klass',
		image: 'plots_comfort_class',
		class: 'order-6 md:order-5 col-span-2 md:col-span-2 md:h-[200px] bg-center max-md:after:bg-gray-600/50 z-10 max-md:after:absolute max-md:after:inset-0',
		text_class: 'max-md:text-white right-5 top-4 max-w-60 text-right z-20'
	},
	{
		label: t('item.houses_with_finishing'),
		to: '/kupit-dom/novaya',
		image: 'houses_with_finishing',
		class: 'order-6 md:order-6 col-span-1 md:col-span-1 md:h-[200px] bg-center md:bg-cover',
		text_class: 'max-md:left-5 max-md:top-1/2 max-md:-mt-6 md:bottom-4 text-left md:text-center'
	},
	{
		label: t('item.houses_for_finishing'),
		to: '/kupit-dom/bez-otdelki',
		image: 'houses_for_finishing',
		class: 'order-7 md:order-7 md:h-[200px] max-md:!bg-none',
		text_class: 'top-4 left-1/2 -ml-20 text-center max-w-40'
	},
	{
		label: t('item.plots_with_reservoir'),
		to: '/poselki/vodoemy',
		image: 'plots_with_reservoir',
		class: 'order-8 md:order-8 col-span-2 md:col-span-2 bg-center',
		text_class: 'max-md:left-1/2 max-md:-ml-20 max-md:bottom-1 md:right-5 bottom-2 text-white'
	},
	{
		label: t('item.plots_elite_class'),
		to: '/poselki/elitnye',
		image: 'plots_elite_class',
		class: 'order-5 md:order-9 col-span-1 md:col-span-3 bg-center',
		text_class: 'right-5 max-md:top-1/2 max-md:-mt-6 max-md:max-w-32 max-md:text-right md:bottom-2 text-white'
	}
]
</script>

<template>
	<LayoutContainer class="mt-8 md:mt-14">
		<h2 class="text-xl md:text-4xl px-4 md:px-0">
			{{ t('title' )}}
		</h2>
		<div class="grid grid-cols-2 md:grid-cols-5 gap-2.5 md:gap-5 mt-4 md:mt-8 px-4 md:px-0">
			<NuxtLink
				v-for="(item, index) in items"
				:key="index"
				:to="item.to"
				class="relative bg-white rounded-lg border border-stone-300 h-20 md:h-32 overflow-hidden bg-no-repeat cursor-pointer hover:opacity-90"
				:class="[item.class]"
				:style="{
					'background-image': item.image.length ? 'url(/images/index/' + item.image + '.jpg)' : ''
				}"
			>
				<span
					class="absolute flex items-center h-12 text-base md:text-xl font-bold line-clamp-2 !leading-6"
					:class="[item.text_class]"
				>
					{{ item.label }}
				</span>
			</NuxtLink>
		</div>
	</LayoutContainer>
</template>

<i18n>
{
	"ru": {
		"title": "Популярное",
		"item": {
			"plots_without_contract": "Участки без подряда",
			"plots_with_contract": "Участки с подрядом",
			"plots_wright_style": "Поселки в стиле Райта",
			"plots_business_class": "Поселки бизнес-класса",
			"plots_comfort_class": "Поселки комфорт и эконом классов",
			"plots_elite_class": "Элитные поселки",
			"plots_with_reservoir": "Поселки с водоемом",
			"houses_with_finishing": "Готовые дома с отделкой",
			"houses_for_finishing": "Готовые дома “под отделку”",
			"houses_complete_up_to_10_million": "Готовые дома до 10 млн.₽"
		}
	}
}
</i18n>