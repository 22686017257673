<script setup lang="ts">
import type { PropType } from 'vue'
import type { Village } from '~/types'
import { PathType } from '~/enums'

const props = defineProps({
	item: {
		type: Object as PropType<Village>,
		required: true
	}
})

const { t: tg } = useI18n({
	useScope: 'global'
})
</script>

<template>
	<div
		class="relative flex flex-col h-52 md:h-96 bg-white rounded-lg shadow-md mb-1 pb-2 overflow-hidden"
	>
		<NuxtLink
			:to="'/' + PathType.Village + '/' + item.slug"
		>
			<UiImage
				v-if="item.images?.length"
				:url-server="item.images[0].url"
				:url-cdn="item.images[0].cdn_url"
				:width="290"
				:height="$isMobile() && !$isTablet() ? 210 : 384"
				:alt="item.title"
				class="block w-full h-full"
			/>
		</NuxtLink>
		<div
			class="absolute bottom-0 left-0 right-0 w-full flex flex-col h-52 bg-gradient-to-t from-gray-800 to-gray-600/0 px-4 py-3 md:py-5 pointer-events-none"
		>
			<div class="w-full mt-auto">
				<div
					v-if="item.class"
					class="text-white text-xs uppercase"
				>
					{{ item.class.title }}
				</div>
				<NuxtLink
					:to="'/' + PathType.Village + '/' + item.slug"
					class="text-white text-lg font-medium !leading-tight pointer-events-auto"
				>
					{{ item.title }}
				</NuxtLink>
				<div
					v-if="item.highways?.length"
					class="flex flex-nowrap gap-1 items-center text-white mt-1"
				>
					<div class="w-5 flex items-center justify-center">
						<UIcon
							name="i-app-car"
							class="text-white w-3 h-3"
						/>
					</div>
					<span class="text-sm font-normal">
            {{ item.highways[0].title }}
          </span>
					<template v-if="item.location?.beltway_distance">
						<span class="text-sm text-white font-light">
							/
						</span>
						<span class="font-normal">
							{{ item.location.beltway_distance }} {{ tg('unit.km') }}
						</span>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>